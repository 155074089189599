@import '../../../variables.scss';

input[type='submit'],
.button {
    border-radius: 5px;
    padding: 4px 32px;
    font-size: 18px;
    margin-bottom: 15px;
    display: block;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    color: #fff;
    background-color: #020202;
    cursor: pointer;
    line-height: 1.5;
    border: 2px solid #020202;
}

.button-wrapper {
    display: flex;
}

.button-wrapper.small {

    input[type='submit'],
    .button {
        font-size: 16px;
        padding: 4px 12px;
        font-weight: 500;
        line-height: 1.5;
    }
}

.button--inverted {
    background-color: white;
    color: #020202;
    margin-right: 10px;
}

.button--delete {
    background: transparent;
    color: $red;
    font-weight: 600;
    border: 2px solid $red;
    transition: all 0.3s ease-in-out;

    &:hover {
        background: $red;
        color: white;
        transition: all 0.3s ease-in-out;
    }
}

.button-group {
    margin-top: 15px;

    .button {
        margin-right: 5px;
        margin-top: 0;
        margin-bottom: 0;
    }
}


.button--inline {
    font-size: 14px !important;
    padding: 5px 10px;
    margin-top: 15px;
}