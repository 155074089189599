.file-input {
    height: 150px;
    overflow: hidden;
    position: relative;
    border: 3px dashed rgb(210, 227, 244);
    justify-content: center;
    align-items: center;
    display: flex;

    input[type='file'] {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        cursor: pointer;
        opacity: 0;
    }
}

.two-squares-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 45px;
    margin-top: 30px;

    .square {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid #ccc;
        padding: 45px;
        //border-radius: 5px;
        width: 100%;
        margin-left: 10px;
        margin-right: 10px;
        box-shadow: #ccc 0px 0px 5px 0px;
        text-decoration: none;
        color: inherit;

        &:hover {
            background-color: #fafafa;
            // adjust box shadow on hover
            // slighlty adjust the background color on hover
            box-shadow: #ccc 0px 0px 10px 0px;
        }

        &__title {
            font-weight: 700;
            font-size: 18px;
            margin-bottom: 5px;
        }

        &__text {
            font-size: 14px;
            max-width: 70%;
            text-align: center;
            line-height: 1.5;
        }
    }
}