.table {

    padding-left: 0;

    table-layout: auto;

    &__row {
        border-bottom: 1px solid #ccc;
        list-style: none;

        &:first-child {
            border-top: 1px solid #ccc;
        }

        a,
        div {
            padding: 0;
            display: inline-block;
            line-height: 2.2;
            padding-left: 5px;
            font-size: 14px;
            width: 100%;

            span {
                width: 200px;
                display: inline-block;
            }
        }

        &.table__row--headline {
            border-top: none;

            span {
                font-weight: 500;
            }
        }
    }
}