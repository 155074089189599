// Slot Selector
.slot-selector-wrapper {
    display: flex;
    margin-bottom: 45px;
    margin-top: 45px;

    select {
        margin-top: 15px;
        width: 100%;
    }


}

.slot-selector {
    padding-top: 15px;
    padding-bottom: 15px;
    //max-height: 300px;
    //overflow-y: scroll;
    overflow-x: visible;
    flex-grow: 2;
    margin-left: 15px;
    padding-right: 15px;

    &--preview {
        border: none;
        margin: 0;

        label {
            cursor: default;
        }
    }

    .no-slots {
        border: 1px solid #D62246;
        color: #D62246;
        padding: 10px;
        border-radius: 5px;
        font-size: 14px;
    }

    &__month {
        font-size: 21px;
        font-weight: 500;
        margin-bottom: 10px;
        display: flex;

        .forward {
            transform: rotate(180deg);
            cursor: pointer;
            transition: ease-in-out 0.2s;

            &:hover {
                transform: rotate(180deg) translateX(-2px);
                transition: ease-in-out 0.2s;
            }
        }

        .back {
            cursor: pointer;
            transition: ease-in-out 0.2s;

            &:hover {
                transform: translateX(-2px);
                transition: ease-in-out 0.2s;
            }
        }
    }
}

.slot-selection {

    &-wrapper {

        input {
            display: none;
        }

    }

    cursor: pointer;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    display: block;
    font-size: 14px;
    position: relative;

    input[type="checkbox"]:checked+& {
        /* Your styles for the checked state */
        /* For example, change the background color */
        border: 1px solid #49A078;
        background-color: rgba(156, 197, 161, 0.6);
        font-weight: 500;
        /* Add any other desired styles */
    }

    .delete {
        position: absolute;
        right: 5px;
        font-size: 12px;
        border: none;
        background: none;
        cursor: pointer;
        padding: 5px;
        top: 0;
        bottom: 0;
        text-decoration: underline;

        &:hover {
            color: red;
            text-decoration: underline;
        }
    }
}