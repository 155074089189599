.workshop-preview {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 5px;

    background-color: rgba(204, 204, 204, 0.2509803922);
    position: relative;

    cursor: pointer;

    &__title {
        font-size: 14px;
        font-weight: 500;
    }

    &__zeit {
        position: absolute;
        top: 10px;
        right: 10px;
        background: lightblue;
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 500;

    }
}

.workshop-preview2 {
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;

    .workshop-preview__title {
        margin-bottom: 5px;
    }

    .workshop-preview2--checkbox {
        label {
            display: flex;
        }
    }

    &--select {
        display: flex;
        margin-bottom: 5px;

        select,
        input {
            margin: 0 !important;
            line-height: 1 !important;
            height: 100%;
        }

        .input-wrapper.small {
            margin: 0;
        }

        select {
            margin-right: 5px !important;
        }
    }
}