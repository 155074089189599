.dashboard-page {
    margin-top: 100px;
    margin-right: 60px;

    .grid-container {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 20px;
    }

    .grid-item {
        background-color: #fff;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;

        .number {
            font-size: 36px;
        }

        .text {
            font-size: 14px;
        }
    }

}

.hub-preview {
    &__name {
        margin-bottom: 10px;
        font-weight: 500;
        margin-top: 30px;
        margin-left: 5px;
        font-size: 21px;
    }
}