.ui-block {
    margin-bottom: 15px;

    h2 {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 0;
        margin-top: 0;
    }

    p {
        margin-top: 0;
        font-size: 14px;
    }
}