.edit-slots-modal,
.dk-modal-wrapper {


    /* custom scrollbar */
    ::-webkit-scrollbar {
        width: 20px;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #d6dee1;
        border-radius: 20px;
        border: 6px solid transparent;
        background-clip: content-box;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #a8bbbf;
    }

    .dk-modal__content {
        max-height: 80vh;
        overflow-x: hidden;
        overflow-y: scroll;
        padding-right: 20px;
        padding-left: 20px;

        position: relative;

        &::after,
        &::before {
            content: "";
            display: block;
            height: 20px;
            background: linear-gradient(to top, white, transparent);
            position: sticky;
            bottom: 0;
            left: 0;
            right: 0;
        }

        &::before {
            top: 0;
            bottom: auto;
            background: linear-gradient(to bottom, white, transparent);
        }

        .slot-selector__abschnitt {
            margin-left: 15px;
            width: 100%;
        }
    }

    .dk-modal {
        padding-left: 0;
        padding-right: 0;
    }

}