.dk-modal-wrapper {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  background-color: rgba(91, 112, 131, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 500;

  &.frontend {
    position: fixed;
    z-index: 1000;
  }
}



.dk-modal {
  width: 600px;
  min-height: 400px;
  padding: 20px;
  border-radius: 5px;
  background: white;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;
  position: relative;
  z-index: 1000;


  &__title {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    border-bottom: 1px solid #ccc;
    height: 60px;
    padding-left: 20px;
    display: flex;
    align-items: center;

    h1 {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 0;
      margin-top: 0;
    }

  }

  &__content {
    margin-top: 20px;
  }
}

.fullwidth {
  .dk-modal {
    width: 1000px;
  }
}

.close-button {
  padding: 5px;
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
}