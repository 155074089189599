.login-form {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    margin-top: 120px;

    .error-message {
        margin-bottom: 10px;
        margin-top: 0;
    }
}

.input-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.columns-2 {
    display: flex;
    justify-content: space-between;

    .input-wrapper {
        width: calc(50% - 5px);
    }
}

.reset-password-link {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.8);
}