.change-password {
    h2 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 5px;
        margin-top: 0;
    }

    p {
        margin-top: 0;
        font-size: 16px;
    }

    input {
        line-height: 1.5;
    }

    .error-message {
        margin-bottom: 10px;
    }
}

a.button {
    border-radius: 5px;
    border: none;
    margin-bottom: 15px;
    font-family: "Roboto", sans-serif;
    color: #fff;
    background-color: #020202;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    font-size: 16px;
    padding: 4px 12px;
    font-weight: 500;
    line-height: 1.5;
}