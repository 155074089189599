#root {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.App {
  height: 100%;
  width: 100%;
}

hr {
  border-left: none;
  border-top: 1px solid #ccc;
  border-radius: 0;
  border-bottom: none;
  border-right: none;
}
/* Roboto Regular (400) */
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Regular.woff') format('woff'),
    url('./fonts/Roboto-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

/* Roboto Light (300) */
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Light.woff') format('woff'),
    url('./fonts/Roboto-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

/* Roboto Medium (500) */
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Medium.woff') format('woff'),
    url('./fonts/Roboto-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

/* Roboto Bold (700) */
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Bold.woff') format('woff'),
    url('./fonts/Roboto-Bold.woff2') format('woff2');
  font-weight: 700; /* 700 is considered bold */
  font-style: normal;
}

/* Add more font-weight variations as needed */
