.kalender {
    margin-top: 30px;

    &__anmeldung-item {
        border: 1px solid #ccc;
        padding: 20px;
        margin-bottom: 15px;
        background-color: #cccccc18;

        .label {
            font-weight: 500;
            margin-bottom: 10px;
        }
    }

    &__workshop-item {
        border: 1px solid #ccc;
        background-color: #fff;
        border-radius: 5px;
        padding: 20px;
        margin-bottom: 5px;
        justify-content: space-between;
        display: flex;
        align-items: center;

        .date {
            font-weight: 500;
            line-height: 1.5;
        }

        .zeit {
            line-height: 1.5;
        }

        .button {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 0;
            margin-left: 5px;
            background-color: #AFE1AF;
            border-color: #097969;
            color: #097969;

            &.not {
                background-color: #FFD580;
                border-color: #CC5500;
                color: #CC5500;
            }

            &.shady {
                opacity: 0.5;
            }
        }
    }
}