.general-inputs {




    textarea,
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="number"] {
        width: 100%;
        box-sizing: border-box;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        max-width: none !important;
        border: 1px solid #ccc;
        position: relative;
        padding-top: 6px;
        padding-bottom: 6px;


        &:focus,
        &:active,
        &:hover {
            outline: none;
            border: 1px solid #4c4c4c;
        }
    }

}

h4 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
    margin-top: 10px;
}

.input-wrapper {
    position: relative;
    margin-top: 10px;

    label {
        font-size: 11px;
        position: absolute;
        top: 0;
        background: white;
        transform: translate(5px, -50%);
        padding-left: 5px;
        padding-right: 5px;
        color: #4c4c4c;
    }
}