.leiter-signup-page {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 120px;

}

.menu {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    margin-top: 50px;

    &--item {
        margin-right: 20px;
        border: 2px solid #ccc;
        padding: 10px 20px;
        color: #4b4b4b;
        cursor: pointer;
        font-weight: bold;

        &.active {
            background-color: #ccc;
        }

        &:hover {
            background-color: #ccc;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}