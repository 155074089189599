.message-box {
  &-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 30px;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    display: flex;

    &.inline {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;

      .message-box {
        bottom: auto;
        width: 100%;
        margin-bottom: 14px;
        box-shadow: none;
        padding: 5px 10px;

        &.active {
          transform: translateY(0);
          transition: none;
          opacity: 1;
        }
      }
    }

  }

  background: #b8d9c2;
  bottom: 50px;
  left: auto;
  right: auto;
  width: 500px;
  padding: 15px 20px;
  border-radius: 5px;
  border: 2px solid #4da167;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
  color: black;
  font-weight: 400;
  //display: none;
  opacity: 0;

  &.active {
    opacity: 1;
    display: block;
    transition: all 0.3s ease-in-out;
    transform: translateY(-20px);
  }

  &.error {
    border: 2px solid #AF3E4D;
    background: #d79fa6;
  }

  &.warning {
    border: 2px solid #FFA74D;
    background: #FFD699;
  }
}

.confirm-submission {
  display: flex !important;
  justify-content: space-between;
  align-items: center;

  &__button {
    background: #FFA74D;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}