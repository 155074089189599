.history {
    &-wrapper {
        margin-top: 15px;
    }

    &-item {
        border: 2px solid #ccc;
        line-height: 2;
        font-size: 14px;
        display: flex;
        flex-direction: row;
        margin-bottom: 5px;
        padding: 10px;
        align-items: center;

        &.confirmed {
            border-color: #4CAF50;
            background-color: #b8d9c2;
        }

        &__date {
            margin-right: 10px;
        }

        &__number {
            border: 2px solid #ccc;
            border-radius: 100%;
            width: 20px;
            height: 20px;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            display: none;
        }
    }
}