.personal-message {
    textarea {
        border: 1px solid #ccc;
        font-size: 16px;
    }
}

.overview-wrapper {
    margin-bottom: 15px;
    border-bottom: 1px solid #cccccc90;
    padding-bottom: 45px;

    &.columns-2 {
        display: flex;
        justify-content: space-between;

        .overview-item {
            flex-basis: 50%;
        }

    }

}

.h2-title {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 5px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
}

.h3-title {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 5px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
}

.general-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    font-family: 'Roboto', sans-serif;
}

.anmeldung-preview {
    @extend .general-text;

    h2 {
        @extend .h2-title;
    }

    h3 {
        @extend .h3-title;
    }

    .abschnitt {
        margin-bottom: 15px;
    }
}

.todo_item {
    border-bottom: 1px solid #ccc;
    line-height: 2;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;

    .form-control {
        display: flex;
        align-items: center;
    }

    input[type="checkbox"] {
        appearance: none;
        background-color: #fff;
        margin: 0;
        font: inherit;
        color: currentColor;
        width: 1.15em;
        height: 1.15em;
        border: 2px solid currentColor;
        border-radius: 0.15em;
        transform: translateY(-0.075em);
        display: grid;
        place-content: center;
        margin-right: 5px;
    }

    input[type="checkbox"]::before {
        content: "";
        width: 0.65em;
        height: 0.65em;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em var(--form-control-color);
        transform-origin: bottom left;
        background-color: CanvasText;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }

    input[type="checkbox"]:checked::before {
        transform: scale(1);
    }
}