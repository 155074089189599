input[type='text'],
input[type='password'],
input[type='number'],
textarea,
select {
    border-radius: 5px;
    padding: 4px 8px;
    border: 1px solid #444444;
    font-size: 18px;
    margin-bottom: 15px;
    display: block;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    resize: none;
    line-height: 2;
}

.input-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.columns-2 {
    .input-wrapper {
        width: calc(50% - 5px);
    }
}

select {
    background: #fff;
}

.input-wrapper--termin {
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid #444444;
    padding-left: 8px;
    padding-right: 8px;

    margin-bottom: 15px;
    position: relative;
    display: inline-block;

    label {
        position: absolute;
        top: 0;
        background: white;
        transform: translateY(-50%);
        padding: 5px;
    }

    input,
    input:focus,
    input:active,
    input:hover {
        text-align: center;
        border: none;
        outline: none;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 18px;
        padding: 16px 8px;
        max-width: 60px;
        border-right: 1px solid rgba(0, 0, 0, 0.5);

        &:last-child {
            border: none;
        }

        &:nth-child(4) {
            border-right: 2px solid rgba(0, 0, 0, 1);
        }
    }
}

.input-wrapper.small {

    input[type='text'],
    input[type='password'],
    textarea,
    select {
        font-size: 16px;
        margin-bottom: 10px;
        max-width: 300px;
    }
}