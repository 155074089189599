.table {
    .row {
        display: flex;
        padding: 10px 0;
        border-bottom: 1px solid #e5e5e5;

        span {
            width: 16%;
            text-align: left;
            flex-grow: 0;
            flex-shrink: 0;
        }

        &.headline {
            font-weight: bold;
        }

        &:last-child {
            border-bottom: none;
        }
    }
}

.info-button {
    margin-left: 2px;
    font-weight: 400;
    text-decoration: none;
    color: rgb(6, 156, 86);
    border-radius: 5px;
    border: 2px solid currentcolor;
    padding-left: 4px;
    padding-right: 4px;
    line-height: 1.5;

    &.not {
        color: rgb(255, 104, 30);
    }
}

.reise-table {
    margin-bottom: 30px;
    ;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mt-20 {
    margin-top: 20px;
}