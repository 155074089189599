.leiterperson {
    background-color: #f5f5f5;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding-left: 4px;
    padding-right: 4px;
    line-height: 2;
    margin-left: 2px;
    margin-right: 2px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    display: flex;

    &.available {
        background-color: #AFE1AF;
        border-color: #097969;
    }

          &.unavailable {
    
              background-color: #FFD580;
              border-color: #CC5500;
          }
}

.leiterpersonen-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}