.login-page {
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    &--left {
        flex-basis: 50%;
    }

    &--right {
        background: #cccccc25;
        flex-basis: 50%;
    }
}