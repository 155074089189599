.plz-input-wrapper {
    display: flex;
    margin-left: -5px;
    margin-right: -5px;

    input,
    select,
    button {
        margin-left: 5px;
        margin-right: 5px;
        max-width: unset;
        width: 100%;
        line-height: 1.5;
    }

    button {
        width: auto;
        border: none;
    }
}

.plz-input-container {
    overflow: hidden;
}

.plz-warning {
    border-radius: 5px;
    padding: 15px 20px;
    border: 2px solid #AF3E4D;
    background: #d79fa6;
    color: black;

    &--green {
        border: 2px solid #4CAF50;
        background: #a6d79f;
        color: black;
    }
}